import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../components/Layout/Layout'
import Banner from '../components/Banner/Banner'

export const query = graphql`
  query ArchivePageQuery {
    sanityPage(slug: {current: {eq: "blog"}}) {
      title
      pageBanner {
        title
        image {
            asset {
                fluid {
                    ...GatsbySanityImageFluid
                }
            }
          }
      }
    }
    posts: allSanityPost(
      sort: {order: DESC, fields: _createdAt}
      filter: { slug: { current: { ne: null } } }
      ) {
      edges {
        node {
          id
          mainImage {
            ...SanityImage
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const ArchivePage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  return (
    <Layout>
        <SEO title='Freedom Wine Blog' />
        <Banner heading={ data.sanityPage.pageBanner.title } image={data.sanityPage.pageBanner.image.asset.fluid} />
        <div className="blog">
          <div className="container">
             {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid nodes={postNodes} />}
          </div>
        </div>
    </Layout>
  )
}

export default ArchivePage;
